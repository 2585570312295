@media screen and (max-width: $screen-size) {
  .mobile-education-card-content {
    padding: pxRem(16) !important;
    border-radius: pxRem(16) !important;
    border-radius: pxRem(16) !important;
    gap: unset !important;
  }
  .mobile-education-REEF-Text {
    font-size: pxRem(18) !important;
    line-height: pxRem(28) !important;
    font-family: 'OpenSans_Bold';
    margin-bottom: pxRem(24) !important;
  }
  .mobile-education-label-text {
    font-size: pxRem(16) !important;
    line-height: pxRem(24) !important;
    font-family: 'OpenSans_Bold';
    margin-bottom: pxRem(16) !important;
  }
  .mobile-education-divRow {
    display: flex !important;
    flex-direction: column !important;
  }
  .mobile-education-financial-advisory-radioText {
    width: 100% !important;
    padding: pxRem(16) !important;
    gap: unset !important;
    border-radius: pxRem(4) !important;
    &:first-child {
      margin-bottom: (16 / $tsc) + rem !important;
    }
  }
  .mobile-education-subCard {
    margin-top: pxRem(16) !important;
    padding: pxRem(16) !important;
    border-radius: pxRem(4) !important;
  }
  .mobile-education-qualification-label-text {
    font-size: pxRem(16) !important;
    line-height: pxRem(24) !important;
    font-family: 'OpenSans_Bold';
    margin-top: pxRem(24) !important;
    margin-bottom: 0 !important;
  }
  .mobile-educationQualification-inputSelect-divRow {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    gap: pxRem(16) !important;
    margin-top: pxRem(16) !important;
  }
  .mobile-education-startAndEndDate {
    margin-top: pxRem(16) !important;
  }
  .mobile-education-original-certificate-divRow {
    margin-top: pxRem(24) !important;
    margin-bottom: pxRem(24) !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .mobile-education-originalCertificate-divRow {
    margin-top: pxRem(24) !important;
    .mobile-education-originalCertificate-divRow-label {
      margin-bottom: 0 !important;
      font-family: 'OpenSans_Bold';
      font-size: pxRem(16) !important;
      line-height: pxRem(24) !important;
    }
  }
  .mobile-education-upload-subCard {
    margin-top: pxRem(24) !important;
    border-radius: pxRem(16) !important;
    padding: pxRem(16) !important;
    .mobile-education-upload-subCard-label {
      font-family: 'OpenSans_Semibold';
      font-size: pxRem(16) !important;
      line-height: pxRem(24) !important;
      letter-spacing: pxRem(0.1) !important;
      margin-bottom: 0 !important;
    }
  }
}
