@media screen and (max-width: $screen-size) {
  .dialog-content {
    display: flex;
    margin-top: 0px !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .content-header {
      display: flex;
      flex-direction: column;
      gap: 24px;
      text-align: center;
      align-items: center;
    }
    .content-text {
      p {
        @include fs(16, $reg);
        margin-top: 8px;
        margin-bottom: 24px;
      }
      h4 {
        @include fs(20, $bold);
      }
    }
    .content-footer {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
  .upload-document-wrap {
    .upload-document-box {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
  .business-card-row {
    flex-direction: column;
    gap: 24px;
    & > div {
      width: 100% !important;
    }
  }
  .radio-sky-blue-wrap {
    .radio-wrap {
      flex-direction: column;
      gap: 24px;
    }
    .textarea-wrap {
      div {
        width: 100% !important;
      }
    }
  }
}
