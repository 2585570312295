@media screen and (max-width: $screen-size) {
  .mobile-welcome-header {
    display: flex;
    height: (200 / $tsc) + rem !important;
    background-image: url('./images/welcome/header-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    .title-wrap {
      padding: (55 / $tsc) + rem 0 0 (25 / $tsc) + rem !important;
      div:first-child {
        font-size: (28 / $tsc) + rem !important;
        line-height: unset !important;
        font-family: 'OpenSans_Semibold' !important;
      }
      div:last-child {
        font-size: (16 / $tsc) + rem !important;
        line-height: unset !important;
        font-family: 'OpenSans_Semibold' !important;
      }
    }
    .person-img {
      height: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
  }
  .mobile-welcome-tip-wrap {
    padding: 0 (12 / $tsc) + rem !important;
    .mobile-welcome-tip {
      font-size: (16 / $tsc) + rem !important;
      display: flex;
      align-items: center;
      margin: 0 !important;
      height: (144 / $tsc) + rem !important;
      font-family: 'OpenSans_Semibold';
      width: (343 / $tsc) + rem;
      letter-spacing: (0.1 / $tsc) + rem;
    }
  }
  .mobile-border-box > div {
    &:first-child {
      display: flex;
      align-items: center;
      img {
        width: (48 / $tsc) + rem !important;
        height: (48 / $tsc) + rem !important;
      }
      div {
        font-size: (18 / $tsc) + rem !important;
        line-height: unset !important;
        font-family: 'OpenSans_Bold';
      }
    }
    &:nth-child(2) {
      display: block !important;
      div > ul {
        padding: (15 / $tsc) + rem 0 0 (26 / $tsc) + rem;
        li {
          font-family: 'OpenSans_Regular' !important;
        }
        width: (311 / $tsc) + rem;
        margin-bottom: (25 / $tsc) + rem;
      }
    }
    &:last-child {
      div > ul {
        font-size: (16 / $tsc) + rem;
        line-height: (24 / $tsc) + rem;
        letter-spacing: (0.1 / $tsc) + rem;
        li {
          font-family: 'OpenSans_Regular' !important;
        }
        padding: (15 / $tsc) + rem 0 0 (26 / $tsc) + rem;
        margin-bottom: 0;
      }
    }
  }
  .mobile-border-box {
    letter-spacing: (0.1 / $tsc) + rem;
    padding: (16 / $tsc) + rem !important;
    &:nth-child(3) {
      div > ul {
        padding: 0 0 0 (16 / $tsc) + rem !important;
      }
    }
    .mobile-bank-title {
      margin-bottom: (24 / $tsc) + rem;
      div:first-child {
        margin-bottom: (8 / $tsc) + rem;
        font-family: 'OpenSans_Semibold' !important;
      }
      div:last-child {
        font-family: 'OpenSans_Regular' !important;
      }
    }
    .mobile-credit-title {
      display: flex;
      flex-direction: column;
      gap: 24px;
      div:first-child {
        margin-bottom: (8 / $tsc) + rem;
        font-family: 'OpenSans_Semibold' !important;
      }
      div:last-child {
        font-family: 'OpenSans_Regular' !important;
      }
    }
    .mobile-declaration-title {
      margin-top: (10 / $tsc) + rem;
      font-family: 'OpenSans_Regular' !important;
      font-size: (16 / $tsc) + rem;
      letter-spacing: (0.1 / $tsc) + rem;
      line-height: (24 / $tsc) + rem;
    }
    a {
      text-decoration: underline !important;
    }
  }
  .mobile-welcome-ul-w {
    width: (295 / $tsc) + rem;
  }
}
