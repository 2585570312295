@media screen and (max-width: $screen-size) {
  .mobile-banner-wrap {
    position: relative;
    .inner {
      height: pxRem(156);
      overflow: hidden;
      .banner-img {
        height: pxRem(156);
        justify-content: flex-end;
        align-items: center;
      }
      .banner-img:first-child {
        background-position: center center;
      }
      .banner-text,
      .banner-text-describe {
        h4 {
          color: #fff;
          @include ifs(24, $A_c_med);
          @include mobile-flex-text-set;
        }
        p {
          color: #fff;
          @include ifs(15, $A_reg);
          @include mobile-flex-text-set;
        }
      }
      .banner-text {
        width: calc(100% - pxRem(222));
        padding-right: pxRem(16);
      }
      .banner-text-describe {
        width: 100%;
        height: 100%;
        flex-direction: column;
        background-color: rgb(0 0 0 / 40%);
        display: flex;
        justify-content: center;
        align-items: center;
        h4 {
          text-align: center;
        }
        p {
          text-align: center;
        }
      }
    }
    .pager-wrap {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      div:not(:last-child) {
        margin-right: pxRem(8);
      }
      div {
        margin-top: pxRem(8);
      }
      .mobile-active,
      .mobile-normal {
        width: pxRem(8);
        height: pxRem(8);
        border-radius: 50%;
      }
      .mobile-active {
        background-color: #d31145;
      }
      .mobile-normal {
        background-color: #b3b1a8;
      }
    }
  }
  .mobile-dashboard-title {
    h4 {
      @include ifs(24, $A_c_med);
    }
    margin-bottom: pxRem(16) !important;
  }
  .mobile-dashboard-h4 {
    h4 {
      @include ifs(24, $A_c_med);
    }
  }
  .mobile-dashboard-p {
    p {
      @include ifs(24, $A_c_med);
    }
  }
  .mobile-dashboard-h4-h5 {
    h4 {
      @include ifs(24, $A_c_med);
    }
    h5 {
      @include ifs(14, $A_med);
    }
  }
  .mobile-GridWrapper {
    margin-top: pxRem(27);
    h4 {
      @include ifs(24, $A_c_med);
      max-width: pxRem(259);
      @include mobile-ellipsis(0);
    }
    div {
      min-height: pxRem(60) !important;
      padding: pxRem(14) 0 pxRem(16) pxRem(68) !important ;
    }
  }
  .mobile-join-status-banner {
    padding: 0 !important;
    min-height: pxRem(262);
    background-image: url('../images/dds/status-banner-bg-mobile.svg');
    background-repeat: no-repeat;
    background-position: left bottom;
    .desc-oper-box {
      padding: 0 !important;
      margin: pxRem(24) pxRem(16) pxRem(24) pxRem(127) !important;
      .description {
        .task-title {
          @include ifs(24, $A_c_med);
          max-width: pxRem(200);
          @include mobile-flex-text-set;
        }
        .status-desc {
          @include ifs(15, $A_reg);
          max-width: pxRem(200);
          @include mobile-flex-text-set;
        }
      }
      .operation {
        margin-top: pxRem(16) !important;
        align-self: unset !important;
        button {
          border-radius: unset;
          span {
            @include ifs(20, $A_c_med);
          }
        }
      }
    }
  }
  .task-banner {
    min-height: pxRem(131) !important;
    padding: pxRem(12) 0 !important;
    display: flex;
    .mobile-ban-img-wrap {
      max-width: pxRem(96);
      display: flex;
      flex: 1;
      justify-content: center;
    }
    .mobile-ban-content {
      max-width: pxRem(231);
      flex: 1;
      @include mobile-flex-text-set;
      .task-title {
        @include ifs(24, $A_c_med);
        line-height: pxRem(26);
      }
      .status-desc {
        @include ifs(15, $A_med);
        line-height: pxRem(21);
        margin-top: pxRem(4);
        text-align: left !important;
      }
      .mobile-ban-btn-wrap {
        width: pxRem(160);
        height: pxRem(40);
        margin-top: pxRem(16);
        button {
          height: 100%;
          border-radius: unset;
          span {
            @include fs(20, $A_c_med);
          }
        }
      }
    }
  }
}
