@media screen and (max-width: $screen-size) {
  .mobile-background-card-div {
    padding: pxRem(16) !important;
    border-radius: pxRem(8) !important;
  }
  .mobile-bankground-RNFNumber-title-label {
    font-size: pxRem(16) !important;
    font-family: 'OpenSans_Semibold';
    line-height: pxRem(24) !important;
    margin-bottom: pxRem(16) !important;
    .mobile-bankground-RNFNumber-underline-title {
      display: inline-block !important;
      margin-top: pxRem(4) !important;
    }
  }
  .mobile-bankground-select-form-options {
    font-size: pxRem(16) !important;
    line-height: pxRem(24) !important;
    font-family: 'OpenSans_Semibold';
    letter-spacing: pxRem(0.1) !important;
    font-weight: unset !important;
    margin-bottom: pxRem(16) !important;
  }
  .mobile-bankground-application-detail-label {
    font-size: pxRem(16) !important;
    line-height: pxRem(24) !important;
    font-family: 'OpenSans_Semibold';
    font-weight: unset !important;
    letter-spacing: pxRem(0.1) !important;
    margin-bottom: 0 !important;
  }
  .mobile-bankground-application-divRow {
    flex-direction: column !important;
    div {
      flex: 1 !important;
    }
    & > div:first-child {
      margin-bottom: pxRem(16) !important;
    }
  }
}
