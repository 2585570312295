$reg: 'OpenSans_Regular';
$semi: 'OpenSans_Semibold';
$bold: 'OpenSans_Bold';
$mixed: 'OpenSans_Light';
$A_bold: 'AIAEverest_Bold';
$A_c_med: 'AIAEverest-CondensedMedium';
$A_med: 'AIAEverest_Medium';
$A_reg: 'AIAEverest_Regular';
@font-face {
  font-family: $reg;
  src: url('../../fonts/openSans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: 'normal';
}
@font-face {
  font-family: $semi;
  src: url('../../fonts/openSans/OpenSans-Semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: 'normal';
}
@font-face {
  font-family: $bold;
  src: url('../../fonts/openSans/OpenSans-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: 'normal';
}
@font-face {
  font-family: $mixed;
  src: url('../../fonts/openSans/OpenSans-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: 'normal';
}
@font-face {
  font-family: $A_c_med;
  src: url('../../fonts/AIAEverest-CondensedMedium.ttf') format('truetype');
  font-weight: normal;
  font-style: 'normal';
}
.regular {
  font-family: $reg !important;
}
.semiBold {
  font-family: $semi !important;
}
.openSansBold {
  font-family: $bold !important;
}
