$total-width: 375;
$tsc: 16;
$screen-size: 540px;

@media screen and (max-width: $screen-size) {
  html {
    font-size: 100vw / $total-width * $tsc !important;
  }

  .student-mobile-header {
    height: (72 / $tsc) + rem !important;

    .student-mobile-header-title {
      font-size: (16 / $tsc) + rem !important;
    }
  }

  .student-mobile-sidebar-horizontal {
    flex: unset !important;
    max-width: unset !important;
    min-width: unset !important;
    width: unset !important;
    background: unset !important;
    border-right: unset !important;
    padding: unset !important;
    height: unset !important;
    overflow-y: unset !important;
    position: unset !important;
  }

  .student-mobile-content-title {
    padding: (12 / $tsc) + rem (16 / $tsc) + rem 0 (16 / $tsc) + rem !important;
    height: (88 / $tsc) + rem !important;
    background-image: url('../img/step-bg.png') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position-y: bottom !important;
    justify-content: normal !important;

    & > div > div > p:first-child {
      line-height: unset !important;
      font-size: (16 / $tsc) + rem !important;
      font-family: 'OpenSans_Bold' !important;
    }

    p:last-child {
      line-height: unset !important;
      font-size: (12 / $tsc) + rem !important;
      font-family: 'OpenSans_Regular' !important;
      margin: 0 !important;
    }
  }

  .student-mobile-step-wrap {
    height: (64 / $tsc) + rem !important;
    padding: 0 (12 / $tsc) + rem;
    display: flex;
    align-items: flex-end;
    gap: 10px; // justify-content: space-between;
  }

  .student-mobile-step-box {
    display: flex;
    align-items: center;
    cursor: pointer;

    .step-active {
      background-color: #1f78ad;
    }

    .step-completed {
      background: #d8edde;
      fill: #3da758;
    }

    .step-disabled {
      cursor: not-allowed;
    }
  }

  .student-mobile-step {
    height: (32 / $tsc) + rem;
    width: (32 / $tsc) + rem;
    border-radius: 50%;
    background: #d6d8da;
    color: #fff;
    font-size: (16 / $tsc) + rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .student-mobile-step-line {
    content: 'span';
    width: (270 / $tsc) + rem;
    height: (2 / $tsc) + rem;
    margin-bottom: (16 / $tsc) + rem;
    background-color: rgb(214, 216, 218);
    border-radius: (48 / $tsc) + rem;

    &:last-child {
      display: none;
    }

    &.step-completed {
      background: #d8edde;
      fill: #3da758;
    }
  }
}

.student-flex-direction-column {
  flex-direction: column !important;
}
