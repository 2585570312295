$reg: 'OpenSans_Regular';
$semi: 'OpenSans_Semibold';
$bold: 'OpenSans_Bold';
$mixed: 'OpenSans_Light';
$A_bold: 'AIAEverest_Bold';
$A_c_med: 'AIAEverest-CondensedMedium';
$A_med: 'AIAEverest_Medium';
$A_reg: 'AIAEverest_Regular';
@function apx($px) {
  @return ($px) + px;
}
@mixin fs($px, $fm) {
  font-size: apx($px);
  font-family: $fm;
  line-height: 24px;
}
@mixin flex-text-set {
  word-wrap: break-word;
  overflow: hidden;
}
.business-wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .business-header {
    line-height: 24px;
    @include fs(16, $bold);
  }
  .business-card {
    display: flex;
    padding: 24px;
    flex-direction: column;
    gap: 24px;
    border-radius: 16px;
    border: 1px solid var(--aia-outline-normal-8-f-959-a, #8f959a);
    .business-card-header {
      display: flex;
      justify-content: space-between;
      p {
        @include fs(20, $bold);
      }
      img {
        cursor: pointer;
      }
    }
    .business-card-row {
      display: flex;
      justify-content: space-between;
      & > div {
        width: calc(50% - 12px);
      }
      .app-form-group__input-trailing {
        width: auto;
        color: #8f959a;
      }
      .app-form-group__input-label--trailing {
        padding-right: 6.6rem !important;
      }
      .app-form-group__input {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .shareholding-wrap {
      display: flex;
      position: relative;
      flex-direction: column;
      gap: 16px;
      h4 {
        @include fs(16, $bold);
      }
    }
    .radioWarp {
      display: flex;
      position: relative;
      flex-direction: column;
      gap: 16px;
      .radioGroupStyle {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 24px;
      }
    }
    .shareholding-num {
      position: absolute;
      width: 44px !important;
      left: 15rem;
      top: -5px;
      input {
        border-left: none;
        border-top: none;
        border-right: none;
        padding: 0 8px;
      }
    }
  }
  .business-footer {
    display: flex;
    justify-content: center;
    p {
      @include fs(16, $semi);
      letter-spacing: 0.1px;
    }
  }
}
.types-title {
  @include fs(16, $bold);
}
.radio-sky-blue-wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .radio-wrap {
    display: flex;
    align-items: flex-start;
    gap: 143px;
    justify-content: space-between;
    align-self: stretch;
    h4 {
      line-height: 24px;
      .title {
        @include fs(16, $bold);
      }
      .subtitle {
        margin-top: 24px;
        @include fs(16, $reg);
        letter-spacing: 0.1px;
      }
    }
    h4 ~ div {
      min-width: 211px;
      gap: 55px;
    }
  }
  .textarea-wrap {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 16px;
    background: var(--aia-primary-blue-5, #ecf1f4);
    div {
      width: 100%;
    }
    .radio-input {
      width: 50%;
    }
    .input-textarea {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 24px;
      h4 {
        p {
          @include fs(16, $bold);
        }
      }
      h4 + div {
        flex: 0;
      }
    }
  }
}
.upload-document-wrap {
  padding: 24px;
  background-color: #f7f7f8;
  border-radius: 16px;
  gap: 24px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  .title {
    @include fs(16px, $semi);
  }
  .upload-document-box {
    display: flex;
    justify-content: space-between;
    .subTitle-wrap {
      flex: 1;
      p {
        margin: 0;
      }
      p:first-child {
        @include fs(20, $bold);
      }
    }
    .upload-document {
      flex: 1;
    }
  }
  .upload-file {
    width: auto !important;
    justify-content: space-between;
    span {
      min-width: 16px;
      @include flex-text-set;
      svg {
        margin-left: 0;
        min-width: 16px;
      }
    }
  }
}
.my-dialog {
  min-width: 560px !important;
  .dialog-content {
    display: flex;
    flex-direction: column;
    margin: -16px -16px;
    padding-bottom: 24px;
    .content-header-wrap {
      border-bottom: 1px solid #f7f7f8;
      .content-header {
        padding: 40px 40px 0 40px;
        text-align: center;
        line-height: 24px;
        .content-text {
          p {
            @include fs(16, $reg);
            margin-top: 8px;
            margin-bottom: 24px;
          }
          h4 {
            @include fs(20, $bold);
          }
        }
      }
    }
    .content-footer {
      display: flex;
      margin-top: 24px;
      align-self: flex-end;
      margin-right: 40px;
      .secondary-btn {
        margin-right: 16px;
      }
    }
  }
}
.question-assembly-wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
