@import './business.scss';
.dialog.crop-image-dialog {
  width: auto;
  .cropper-point,
  .cropper-line {
    background-color: initial;
  }
  .cropper-view-box {
    border-radius: 50%;
    outline: 0;
  }
  .cropper-face {
    background-color: initial;
  }
  .cropper-container {
    margin: 0 auto;
  }
  .modal-header {
    border-bottom: 0;
  }
}
.my-tabs {
  .tabs__header {
    & > div {
      flex: 1;
    }
    .my_tab {
      display: flex;
      width: 100%;
      flex-direction: row !important;
      .criteriaTabImg {
        width: 24px;
        height: 24px;
        margin-left: 0.6rem;
      }
    }
    .tab:hover {
      border-bottom: 1px solid rgb(214, 216, 218);
    }
    .tab--active:hover {
      border-bottom: 0.125rem solid #1f78ad;
    }
  }
}
.check-comments {
  display: flex;
  min-height: 48px;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  flex: 1;
  .btn--tertiary {
    text-decoration: underline;
    margin-left: auto;
    min-width: max-content;
  }
  img {
    width: 24px;
    height: 24px;
    overflow: initial;
  }
  p {
    margin: 0;
    margin-left: 8px;
    @include flex-text-set;
    @include fs(16, $reg);
  }
}
.check-comments-report {
  background-color: rgba(232, 241, 246, 1);
}
.check-comments-alert {
  background-color: #fcf1c8;
}
.check-comments-warning {
  background-color: rgba(186, 3, 97, 0.1);
}
.basic-alert-wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
