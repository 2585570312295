$total-width: 375;
$tsc: 16;
$screen-size: 540px;
@function pxRem($px) {
  @return ($px/$tsc) + rem;
}
@mixin fs($px, $fm) {
  font-size: pxRem($px);
  font-family: $fm;
}
@mixin ifs($px, $fm) {
  font-size: pxRem($px) !important;
  font-family: $fm !important;
}
@mixin mobile-big-modal-body {
  .modal-body {
    height: 100%;
    padding: 0;
    & > div:nth-child(1) {
      margin-top: pxRem(40);
      height: calc(100% - pxRem(120));
      padding-left: pxRem(16);
      padding-right: pxRem(20);
      img {
        width: pxRem(180);
        height: pxRem(180);
      }
      & > div:not(:nth-child(2), :nth-child(1)) > p {
        font-size: pxRem(16);
        font-family: $reg;
        line-height: pxRem(24);
        letter-spacing: pxRem(0.1);
      }
      & > div:not(:nth-child(2), :nth-child(1)) > div > p {
        font-size: pxRem(16);
        font-family: $reg;
        line-height: pxRem(24);
        letter-spacing: pxRem(0.1);
      }
      & > div:nth-child(2) > p {
        font-family: $bold;
        font-size: pxRem(18);
      }
    }
    & > div:nth-child(2) {
      height: pxRem(80);
      margin: 0;
      padding: pxRem(16);
      flex-direction: column;
      button {
        width: auto;
        flex: 1;
        span {
          font-family: $semi;
        }
      }
    }
  }
}
@mixin mobile-flex-text-set {
  word-wrap: break-word;
  overflow: hidden;
}
@mixin mobile-ellipsis($dw) {
  width: calc(100% - pxRem($dw));
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media screen and (max-width: $screen-size) {
  html {
    font-size: 100vw / $total-width * $tsc !important;
  }
  .mobile-header {
    height: (72 / $tsc) + rem !important;
    .mobile-header-title {
      font-size: (16 / $tsc) + rem !important;
    }
  }
  .mobile-modal-dialog {
    width: (296 / $tsc) + rem;
    height: (460 / $tsc) + rem;
    align-self: center;
    margin: 0 auto;
    .modal-header {
      display: none;
    }
    .modal-body {
      img {
        width: (144 / $tsc) + rem;
        height: (144 / $tsc) + rem;
      }
      & > div:nth-child(2) {
        font-size: (20 / $tsc) + rem;
        font-family: 'OpenSans_Bold';
      }
      & > div:nth-child(3) {
        font-size: (14 / $tsc) + rem;
        font-family: 'OpenSans_Regular';
      }
      & > div:last-child {
        margin-top: (24 / $tsc) + rem;
        flex-direction: column;
        button:first-child {
          order: 1;
        }
        button:last-child {
          order: 0;
          background-color: #d31145;
        }
      }
    }
  }
  .select-custom__options {
    z-index: 1000 !important;
  }
  .mobile-big-modal-dialog {
    width: auto !important;
    border-radius: pxRem(16) pxRem(16) 0 0 !important;
    height: calc(100% - pxRem(45));
    align-self: flex-end;
    @include mobile-big-modal-body;
  }
  .mobile-footer {
    padding: (16 / $tsc) + rem !important;
    height: (80 / $tsc) + rem !important;
    div:last-child {
      height: (48 / $tsc) + rem !important;
      width: (343 / $tsc) + rem !important;
      button {
        border-radius: (8 / $tsc) + rem;
        min-width: (343 / $tsc) + rem !important;
        padding: 0;
        height: 100% !important;
        span {
          font-size: (16 / $tsc) + rem !important;
          line-height: unset !important;
        }
      }
    }
  }
  .dds-comments {
    border-radius: (4 / $tsc) + rem !important;
    font-size: (14 / $tsc) + rem !important;
    font-weight: normal !important;
    padding: (8 / $tsc) + rem (12 / $tsc) + rem !important;
    & + & {
      margin-top: 0 !important;
    }
    margin-bottom: (22 / $tsc) + rem !important;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
  .app-form-group {
    label {
      pointer-events: none;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: $reg !important;
    }
    input {
      font-family: $reg !important;
    }
  }
  .form-control {
    font-family: $reg !important;
  }
  .select-custom {
    display: block !important;
    height: auto !important;
    input {
      font-family: $reg !important;
    }
    label {
      font-family: $reg !important;
    }
  }
  .left-pos {
    .app-form-group__label {
      left: pxRem(-68) !important;
    }
  }
  .mobile-input-select {
    .app-form-group {
      .app-form-group__input-leading {
        width: pxRem(51) !important;
      }
    }
  }
  .nb-xs {
    .select-custom__trigger-light > input {
      padding-top: (4 / $tsc) + rem !important;
    }
    .select .select-custom__trigger-light::after {
      top: 59% !important;
    }
  }
  .nb-n-xs {
    .app-form-group__input-leading--label {
      padding-top: (2 / $tsc) + rem !important;
    }
  }
  .nl-0 {
    .app-form-group__input-leading--label {
      left: 0 !important;
    }
  }
  .table-condensed {
    margin: 0 auto !important;
  }
  .mobile-separate {
    width: auto !important;
    margin: 0 !important;
    margin-top: (23 / $tsc) + rem !important;
  }
  .tooltip-inner {
    font-size: (14 / $tsc) + rem !important;
    line-height: unset !important;
  }
  .mobile-link-dds {
    color: #1f78ad !important;
  }
  .upload-file {
    height: auto !important;
    width: auto !important;
    min-height: (32 / $tsc) + rem !important;
    justify-content: space-between;
    span {
      @include mobile-flex-text-set;
      &:first-child {
        flex: 1;
      }
    }
  }
  .date-picker-wrapper {
    label {
      @include mobile-ellipsis(24);
    }
  }
  .fs-14 {
    font-size: (14 / $tsc) + rem !important;
  }
  .fs-16 {
    font-size: (16 / $tsc) + rem !important;
  }
  .fs-18 {
    font-size: (18 / $tsc) + rem !important;
  }
  .fs-20 {
    font-size: (20 / $tsc) + rem !important;
  }
  .mobile-attachments-rowDiv {
    padding: (16 / $tsc) + rem !important;
    & > div:first-child {
      & > div:first-child {
        width: (48 / $tsc) + rem !important;
        height: (48 / $tsc) + rem;
        line-height: (48 / $tsc) + rem !important;
      }
      & > div:last-child {
        font-size: (16 / $tsc) + rem !important;
      }
    }
    & > div:last-child {
      width: (81 / $tsc) + rem;
      height: (32 / $tsc) + rem;
      border-radius: (8 / $tsc) + rem;
      button {
        min-width: auto !important;
        width: 100%;
        height: 100%;
        font-family: 'OpenSans_Semibold' !important;
        span {
          font-size: (14 / $tsc) + rem !important;
        }
      }
    }
  }
  .alert-message {
    font-family: 'OpenSans_Regular' !important;
  }
  .documentCard-wrap {
    & > div:first-child {
      font-size: (16 / $tsc) + rem;
      font-family: 'OpenSans_Semibold';
      padding: 0 (16 / $tsc) + rem;
      letter-spacing: (0.1 / $tsc) + rem;
    }
    & > div:last-child {
      & > div:first-child {
        width: 100% !important;
        padding-top: 0 !important;
        padding-bottom: (12 / $tsc) + rem !important;
        & > div:first-child {
          margin-left: (16 / $tsc) + rem !important;
          margin-right: (16 / $tsc) + rem !important;
          font-size: (18 / $tsc) + rem;
          font-family: 'OpenSans_Bold';
          letter-spacing: (0.1 / $tsc) + rem;
        }
        & + div {
          width: 100%;
          padding: 0 (28 / $tsc) + rem (16 / $tsc) + rem (28 / $tsc) + rem !important;
          span {
            font-family: 'OpenSans_Semibold';
          }
        }
      }
    }
  }
  .mobile-ol {
    padding-left: (29 / $tsc) + rem;
    font-size: (16 / $tsc) + rem;
    width: (325 / $tsc) + rem;
    font-family: 'OpenSans_Regular';
    letter-spacing: (0.1 / $tsc) + rem;
    line-height: (24 / $tsc) + rem;
  }
  .mobile-uploadForm-wrap {
    margin-top: (16 / $tsc) + rem !important;
    padding: (16 / $tsc) + rem !important;
    & > div:first-child {
      font-family: 'OpenSans_Semibold';
      font-size: (16 / $tsc) + rem;
      line-height: (24 / $tsc) + rem;
      letter-spacing: (0.1 / $tsc) + rem;
    }
    & > div:nth-child(2) {
      padding-top: (24 / $tsc) + rem;
      & > div:first-child {
        width: 100%;
        & > div:first-child {
          padding-top: 0;
          font-size: (18 / $tsc) + rem;
          font-family: 'OpenSans_Bold';
          padding-bottom: (12 / $tsc) + rem;
        }
      }
      & > div:last-child {
        width: 100%;
        margin-top: (12 / $tsc) + rem;
      }
    }
  }
  .datepicker {
    z-index: 1000 !important;
  }
}
.not-display {
  display: none !important;
}
.pa-0 {
  padding: 0 !important;
}
.pa-16 {
  padding: pxRem(16) !important;
}
.ma-0 {
  margin: 0 !important;
}
.ma-16 {
  margin: (16 / $tsc) + rem !important;
}
.not-lineHeight {
  line-height: unset !important;
}
.mt-30 {
  margin-top: (30 / $tsc) + rem !important;
}
.mt-24 {
  margin-top: (24 / $tsc) + rem !important;
}
.mt-16 {
  margin-top: (16 / $tsc) + rem !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-5 {
  margin-right: (5 / $tsc) + rem !important;
}
.ml-5 {
  margin-left: (5 / $tsc) + rem !important;
}
.mb-8 {
  margin-bottom: (8 / $tsc) + rem !important;
}
.mb-16 {
  margin-bottom: (16 / $tsc) + rem !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-12 {
  padding-top: (12 / $tsc) + rem;
}
.pt-16 {
  padding-top: (16 / $tsc) + rem;
}
.pt-20 {
  padding-top: (20 / $tsc) + rem;
}
.pt-24 {
  padding-top: (24 / $tsc) + rem;
}
.px-16 {
  padding-left: (16 / $tsc) + rem;
  padding-right: (16 / $tsc) + rem;
}
.pb-12 {
  padding-bottom: (12 / $tsc) + rem;
}
.pb-16 {
  padding-bottom: (16 / $tsc) + rem;
}
.pb-20 {
  padding-bottom: (20 / $tsc) + rem;
}
.pb-24 {
  padding-bottom: (24 / $tsc) + rem;
}
.flex-direction-column {
  flex-direction: column !important;
}
