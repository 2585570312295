@media screen and (max-width: $screen-size) {
  .mobile-faster-form-div {
    padding: pxRem(16);
    div {
      margin: unset;
      padding: unset;
    }
    .mobile-faster-DivRow1 {
      div {
        @include fs(18, $bold);
      }
    }
    .mobile-faster-DivRow2 {
      padding-top: pxRem(8) !important;
      div {
        @include fs(16, $reg);
      }
    }
    height: auto !important;
    @include mobile-flex-text-set;
    display: flex;
    flex-direction: column;
    .mobile-faster-img {
      padding-top: pxRem(16);
      align-self: flex-end;
      img {
        max-width: pxRem(220);
      }
    }
  }
  .mobile-StyledLabel-wrap {
    display: flex;
    align-items: center;
    max-width: pxRem(275);
    & > div:first-child {
      @include mobile-flex-text-set;
    }
  }
  .mobile-upload-img-div {
    margin: (28.57 / $tsc) + rem 0 !important;
    .photo-container {
      width: (57.43 / $tsc) + rem !important;
      padding: 0 !important;
      & > img:first-child {
        width: (55 / $tsc) + rem !important;
      }
      & > div:last-child {
        width: (20 / $tsc) + rem !important;
        height: (20 / $tsc) + rem !important;
        padding: 0 !important;
        bottom: 0;
        right: 0;
        img {
          width: (8 / $tsc) + rem;
        }
      }
    }
    &div:last-child {
      font-size: (20 / $tsc) + rem !important;
    }
  }
  .mobile-upload-avatar-dialog {
    width: 100% !important;
    align-self: flex-end;
    border-radius: pxRem(16) pxRem(16) 0 0 !important;
    .modal-header {
      padding: 0 pxRem(16);
      line-height: pxRem(48);
      border: none;
      .btn-close {
        background-image: none;
        margin: 0;
        width: auto;
        position: absolute;
        height: auto;
        padding: 0;
        opacity: 1;
        &::before {
          content: 'Cancel';
          color: #333d47;
          @include fs(14, $reg);
        }
      }
      &::after {
        content: 'Profile photo upload';
        @include fs(16, $semi);
        display: block;
        margin: 0 auto;
      }
    }
    .modal-body {
      .dialog__container {
        padding: 0;
        align-items: flex-start;
        .clip-content {
          width: 100%;
        }
        .save-btn {
          width: 100%;
          height: pxRem(48);
          border-radius: pxRem(8);
          background: #d31145;
          margin-top: pxRem(9);
          margin-bottom: pxRem(16);
          border: none;
          span {
            @include fs(16, $reg);
            color: #fff;
          }
        }
      }
    }
  }
  .cropper-bg {
    max-height: pxRem(228) !important;
  }
  .mobile-baseInfo-div-row {
    padding-top: 0 !important;
    margin-top: (25 / $tsc) + rem !important;
  }
  .mobile-des-div-person {
    width: 100%;
    padding: 0 (16 / $tsc) + rem !important;
    font-family: 'OpenSans_Semibold';
    @include mobile-flex-text-set;
  }
  .mobile-title-div-person {
    & > div:first-child {
      margin-left: (16 / $tsc) + rem !important;
      font-size: (18 / $tsc) + rem !important;
      font-family: 'OpenSans_Bold';
    }
    & + div {
      padding: 0 (28 / $tsc) + rem (16 / $tsc) + rem (28 / $tsc) + rem !important;
    }
  }
  .mobile-item-title-wrap {
    padding: 0 !important;
    padding-top: (24 / $tsc) + rem !important;
    img {
      width: (80 / $tsc) + rem;
      height: (80 / $tsc) + rem;
    }
    & > div:last-child {
      font-family: 'OpenSans_Bold';
      font-size: (20 / $tsc) + rem;
    }
    & > div > span:nth-child(2) {
      font-size: (16 / $tsc) + rem;
      letter-spacing: (0.1 / $tsc) + rem;
      font-family: 'OpenSans_Regular';
      margin-top: (16 / $tsc) + rem;
    }
  }
  .mobile-form-border-div {
    padding: (16 / $tsc) + rem;
  }
  .mobile-add-module-wrap {
    height: auto !important;
    margin-top: (27 / $tsc) + rem !important;
    font-family: 'OpenSans_Semibold';
  }
  .mobile-work-question-from-block {
    margin: (16 / $tsc) + rem 0 (24 / $tsc) + rem 0;
    flex-direction: column;
    font-family: 'OpenSans_Bold';
    .mobile-radio1b {
      width: auto;
      float: none;
      margin: 0;
      margin-top: (24 / $tsc) + rem;
      display: flex;
      font-family: 'OpenSans_Regular';
      & > div:first-child {
        flex: 1;
      }
      & > div:last-child {
        flex: 2;
      }
    }
  }
  .mobile-question-content-div {
    & > div:first-child {
      margin: 0 16px !important;
      padding-top: 16px !important;
      & > div:first-child {
        font-family: 'OpenSans_Semibold';
      }
      & > div:nth-child(3) {
        font-size: (14 / $tsc) + rem;
      }
    }
  }
  .mobile-work-input-wrap {
    display: flex;
    justify-content: space-between;
    .mobile-work-input-del {
      padding-right: (12 / $tsc) + rem !important;
      input {
        text-overflow: ellipsis;
      }
    }
    .mobile-work-input-del + .col-1 {
      align-self: center;
    }
  }
  .mobile-gap-div {
    margin-top: (30 / $tsc) + rem;
    padding: (16 / $tsc) + rem;
    gap: 0;
    .work-gap-box {
      display: flex;
      flex-direction: column;
      margin-top: (24 / $tsc) + rem;
      &:not(:first-child) {
        border-top: 1px solid #dbdad4;
        padding-top: (26 / $tsc) + rem;
      }
      .work-gap-top-wrap {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
          display: flex;
          justify-content: space-between;
          &:not(:first-child) {
            margin: (12 / $tsc) + rem 0;
          }
          p {
            word-wrap: break-word;
            max-width: (151 / $tsc) + rem;
          }
          p:first-child {
            font-size: (14 / $tsc) + rem;
            font-family: 'OpenSans_Regular';
          }
          p:last-child {
            font-size: (16 / $tsc) + rem;
            font-family: 'OpenSans_Semibold';
            text-align: right;
          }
        }
      }
      .work-gap-bottom-wrap {
        & > p:first-child {
          font-size: (16 / $tsc) + rem;
          font-family: 'OpenSans_Semibold';
          margin-bottom: (16 / $tsc) + rem;
        }
      }
    }
  }
}
