@media screen and (max-width: $screen-size) {
  .mobile-viewDoc {
    .silderStyle {
      padding-right: pxRem(16) !important;
      .tagDiv {
        .aia-tag {
          width: 100%;
          span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .docName,
      .dateLabel {
        @include mobile-ellipsis(0);
      }
    }
    .mainContent {
      .footer {
        padding: 0 pxRem(16);
        flex-direction: column;
        justify-content: space-evenly;
        button {
          margin: 0;
          height: pxRem(40);
          span {
            font-size: pxRem(14);
            line-height: pxRem(16);
          }
        }
      }
    }
  }
}
