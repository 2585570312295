@media screen and (max-width: $screen-size) {
  #bankinformation {
    .bankinfoTitle {
      font-size: pxRem(20);
      font-family: $bold;
      margin-bottom: pxRem(16);
    }
    .mobile-bank-detail-div {
      margin-bottom: pxRem(16);
      div {
        width: 100%;
        align-items: center;
      }
      & > div:first-child {
        margin-bottom: pxRem(16);
      }
      &:nth-child(4) > div:last-child > div:last-child {
        flex: 2;
      }
      &:nth-child(5) {
        margin-bottom: 0;
      }
      .branchCodeIdentifyText {
        font-family: $semi;
        font-size: pxRem(14);
      }
    }
    .uploadDocLabel {
      font-size: pxRem(16);
      font-family: $semi;
    }
    .proofDetailText {
      font-size: pxRem(18);
      font-family: $bold;
      margin-bottom: pxRem(12);
    }
    .upload-wrap {
      padding: 0 pxRem(12) !important;
    }
  }
  #cbsreport {
    margin-top: pxRem(24);
    .creditReportText {
      font-size: pxRem(20);
      font-family: $bold;
    }
    .creditReportText ~ div {
      display: flex;
      align-items: center;
    }
    & > div:nth-child(2) {
      font-size: pxRem(16);
      font-family: $reg;
      margin-bottom: pxRem(16);
      letter-spacing: pxRem(0.1);
    }
    .buyCredit {
      margin-left: 0 !important;
    }
    .creditUploadDocLabel {
      font-size: pxRem(16);
      font-family: $semi;
    }
    .proofDetailText {
      font-size: pxRem(18);
      font-family: $bold;
      margin-bottom: pxRem(12);
    }
    .upload-wrap {
      padding: 0 pxRem(12) !important;
    }
    .loansQuestion {
      font-size: pxRem(16);
      font-family: $bold;
    }
    & > div:nth-child(5) {
      & > div:first-child {
        padding: 0;
        margin: 0;
        align-items: center;
        & > div:first-child {
          flex: 1;
          label {
            @include fs(16, $reg);
          }
          & > div:first-child {
            flex: 1;
          }
          & > div:last-child {
            flex: 2;
          }
        }
      }
      .mobile-loans-card {
        margin: 0;
        margin-bottom: pxRem(24);
        padding: pxRem(16);
        .creditorTitle {
          @include fs(16, $bold);
        }
        & > div {
          margin-bottom: pxRem(16);
        }
        & > div:not(:first-child) {
          flex-direction: column;
          & > div {
            width: 100%;
          }
          & > div:first-child {
            margin-bottom: pxRem(16);
            &:last-child {
              margin-bottom: 0;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .mobile-add-document {
      padding-top: pxRem(12);
      div {
        margin: 0;
      }
      & > div:first-child {
        height: pxRem(24);
        line-height: pxRem(24);
        margin-bottom: pxRem(28);
        span {
          font-size: pxRem(16);
          font-family: $semi;
        }
      }
      & > div:last-child {
        margin-bottom: pxRem(24);
        display: flex;
        justify-content: space-between;
        & > span:first-child {
          max-width: pxRem(217);
          margin: 0;
          line-height: pxRem(24);
          font-size: pxRem(16);
          flex: 1;
          font-family: $semi;
          // align-self: center;
          text-align: left;
          @include mobile-flex-text-set;
        }
        & > span:last-child {
          line-height: pxRem(32);
          flex: 1;
          @include mobile-flex-text-set;
          font-size: pxRem(18);
          font-family: $semi;
          align-self: center;
        }
      }
    }
  }
  #taxresidence {
    margin-top: pxRem(24);
    .taxResidenceTitle {
      font-size: pxRem(20);
      font-family: $bold;
    }
    .taxResidenceCRS {
      font-family: $reg;
      font-size: pxRem(16);
    }
    .mobile-moreInfoText {
      font-family: $reg;
      font-size: pxRem(16);
    }
    .mobile-taxResidenceCard {
      margin-top: pxRem(24);
      & > div:first-child {
        margin: 0;
        padding: pxRem(16);
        & > div:nth-child(2) {
          flex-direction: column;
          div {
            width: auto;
            flex: 1;
          }
          & > div:first-child {
            margin-bottom: pxRem(16);
          }
          & > div:last-child {
            & > div:first-child {
              margin-bottom: pxRem(8);
            }
            .moreTinInformation {
              @include fs(16, $reg);
            }
          }
        }
        .mobile-TaxResidencedSection {
          & > p:first-child {
            margin-top: pxRem(24);
            @include fs(16, $bold);
          }
          .RadioWithContainer {
            margin-top: pxRem(16);
            label {
              & > span:first-child {
                @include fs(16, $bold);
              }
              & > span:last-child {
                @include fs(16, $reg);
              }
            }
            .radio-label {
              display: block;
            }
          }
          .leavingResonTextArea {
            margin-left: 0;
          }
        }
      }
    }
    .mobile-add-document {
      margin: pxRem(36);
      height: pxRem(24);
      line-height: pxRem(24);
      span {
        @include fs(16, $semi);
      }
    }
  }
  #fatcadeclaration {
    margin-top: pxRem(24);
    .FATCADeclareTitle {
      @include fs(20, $bold);
    }
    .affiliateTitle {
      @include fs(16, $bold);
    }
    .affiliateText {
      @include fs(16, $reg);
    }
    .mobile-AffiliateRadio {
      padding-top: pxRem(32);
      padding-bottom: pxRem(24);
      height: auto;
      & > div:first-child {
        width: 100%;
        & > div:first-child {
          flex: 1;
        }
        & > div:last-child {
          flex: 2;
        }
        label {
          @include fs(16, $reg);
        }
      }
    }
    .mobile-AffiliateRelevantDiv {
      padding: pxRem(16);
      .relevantOptionTitle {
        @include fs(16, $bold);
      }
      .affiliateRelevantList {
        margin-top: pxRem(16);
        p {
          @include fs(16, $reg);
          flex: 1;
        }
      }
      .moreDetails {
        margin-top: pxRem(16);
      }
      .mobile-DownloadSection {
        margin: pxRem(24) 0 0 0 !important;
        padding: pxRem(16) pxRem(4);
        border-radius: pxRem(16);
        .downloadNotes {
          @include fs(16, $semi);
        }
        .downloadFormTitle {
          @include fs(18, $bold);
          margin-top: pxRem(24);
        }
        .downloadAndAdd {
          flex-direction: column;
          & > div:first-child {
            width: auto;
          }
          & > div:last-child {
            width: auto;
          }
          .downloadPdf {
            @include fs(14, $semi);
            margin-bottom: pxRem(12);
            img {
              vertical-align: middle;
              width: pxRem(16);
              height: pxRem(16);
              margin-right: pxRem(1) !important;
            }
          }
          // .downloadItem {
          //   flex-direction: column;
          //   margin-top: pxRem(12);
          //   div {
          //     align-items: center;
          //     a {
          //       @include fs(14, $semi);
          //       margin-right: pxRem(1) !important;
          //     }
          //     svg {
          //       width: pxRem(16);
          //       height: pxRem(16);
          //     }
          //   }
          //   & > div:last-child {
          //     margin-top: pxRem(12);
          //     margin-bottom: pxRem(12);
          //   }
          // }
        }
      }
    }
  }
}
