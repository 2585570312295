@media screen and (max-width: $screen-size) {
  .mobile-jobSource-question-title {
    font-size: pxRem(18) !important;
    line-height: pxRem(18) !important;
    font-weight: 700 !important;
    font-family: $bold !important;
  }
  .mobile-jobSource-subGap {
    display: flex !important;
    flex-direction: column !important;
    gap: pxRem(16) !important;
    label {
      font-family: $reg !important;
      font-size: pxRem(16) !important;
      line-height: pxRem(24) !important;
      letter-spacing: pxRem(0.1) !important;
    }
    .mobile-jobSource-card {
      padding: pxRem(16) !important;
      border-radius: pxRem(8) !important;
      gap: pxRem(24) !important;
      margin-bottom: pxRem(40) !important;
      font-size: pxRem(16) !important;
      .mobile-jobSource-card-label {
        margin-bottom: pxRem(16) !important;
        font-size: pxRem(16);
        font-weight: 600 !important;
        line-height: pxRem(24);
      }
    }
    .mobile-jobSource-checkbox-label .mobile-jobSource-textArea-label {
      font-size: pxRem(14) !important;
      line-height: pxRem(24) !important;
    }
  }
}
