@media screen and (max-width: $screen-size) {
  .mobile-CMFAS-obtailFollowing-label {
    font-family: 'OpenSans_Regular';
    font-size: pxRem(16) !important;
    line-height: pxRem(24) !important;
    letter-spacing: pxRem(0.1) !important;
    margin-bottom: 0 !important;
  }
  .mobile-CMFAS-M5-answerYes-cardDiv {
    padding: pxRem(16) !important;
    border-radius: pxRem(16) !important;
    gap: unset !important;
  }
  .mobile-CMFAS-M5-answerYes-cardDiv-label {
    font-family: 'OpenSans_Semibold';
    font-size: pxRem(16) !important;
    line-height: pxRem(24) !important;
    letter-spacing: pxRem(0.1) !important;
    margin-bottom: 0 !important;
  }
  .mobile-CMFAS-M5-answerYes-M5Certificate-label {
    margin-top: pxRem(24) !important;
    font-family: 'OpenSans_Bold';
    font-size: pxRem(18) !important;
    line-height: pxRem(28) !important;
    margin-bottom: pxRem(16) !important;
  }
  .mobile-CMFAS-M5-answerYes-M5Certificate-document {
    margin-top: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    gap: pxRem(16) !important;
    .mobile-CMFAS-M5-answerYes-M5Certificate-datepicker {
      width: 100% !important;
    }
  }
  .mobile-CMFAS-M5-answerNo-cardDiv,
  .mobile-CMFAS-M9-answerNo-cardDiv {
    padding: pxRem(16) !important;
    border-radius: pxRem(16) !important;
    gap: pxRem(16) !important;
  }
  .mobile-CMFAS-M5-answerNo-cardDiv-label,
  .mobile-CMFAS-M9-answerNo-cardDiv-label {
    font-family: 'OpenSans_Regular';
    font-size: pxRem(16) !important;
    line-height: pxRem(24) !important;
    letter-spacing: pxRem(0.1) !important;
    margin-bottom: 0 !important;
  }
  .mobile-CMFAS-M5-answerNo-cardDiv-button,
  .mobile-CMFAS-M9-answerNo-cardDiv-button {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    gap: unset !important;
  }
  .mobile-CMFAS-M9-cardDiv {
    padding: pxRem(16) !important;
    border-radius: pxRem(16) !important;
  }
  .mobile-CMFAS-M9-upload-label {
    font-family: 'OpenSans_Semibold';
    font-size: pxRem(16) !important;
    line-height: pxRem(24) !important;
    letter-spacing: pxRem(0.1) !important;
    margin-bottom: 0 !important;
  }
  .mobile-CMFAS-M9-uploadFile-ORlabel {
    font-family: 'OpenSans_Regular';
    font-size: pxRem(16) !important;
    line-height: pxRem(24) !important;
    margin-bottom: 0 !important;
  }
  .mobile-CMFAS-M9-uploadFile-label {
    margin-bottom: pxRem(16) !important;
    font-family: 'OpenSans_Bold';
    font-size: pxRem(18) !important;
    line-height: pxRem(28) !important;
  }
  .mobile-CMFAS-M9-answerYes-certificate {
    display: flex !important;
    flex-direction: column !important;
    gap: pxRem(16) !important;
    margin-top: 0 !important;
    .mobile-CMFAS-M9-answerYes-certificate-datepicker {
      width: 100% !important;
    }
  }
  .mobile-CMFAS-M9-answerExempted-cardDiv {
    padding: pxRem(16) !important;
    border-radius: pxRem(16) !important;
    gap: pxRem(16) !important;
    display: flex !important;
    flex-direction: column !important;
    .mobile-CMFAS-M9-answerExempted-label {
      font-family: 'OpenSans_Semibold' !important;
      font-size: pxRem(16) !important;
      line-height: pxRem(24) !important;
      margin-bottom: 0 !important;
    }
  }
}
