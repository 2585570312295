@media screen and (max-width: $screen-size) {
  // check box Group components
  .mobile-StyledCheckboxContainer {
    gap: pxRem(16) !important;
    input {
      width: pxRem(20) !important;
      height: pxRem(20) !important;
    }
    span {
      gap: unset !important;
      flex: 1 !important;
    }
    .mobile-StyledCheckboxContainer-label {
      font-family: 'OpenSans_Regular';
      font-size: pxRem(16) !important;
      line-height: pxRem(24) !important;
      letter-spacing: pxRem(0.1) !important;
    }
  }
  // Radio Question components
  .mobile-radio-question-div {
    .mobile-radio-question-label {
      @include ifs(18, $bold);
      line-height: pxRem(28) !important;
      margin-bottom: 0 !important;
      gap: pxRem(24) !important;
    }
    .mobile-radio-question-subLabel {
      label {
        font-family: 'OpenSans_Regular';
        font-size: pxRem(16) !important;
        line-height: pxRem(24) !important;
        letter-spacing: pxRem(0.1) !important;
        margin-top: pxRem(8) !important;
        margin-bottom: 0 !important;
      }
    }
  }
  // radio group component
  .mobile-radio {
    margin-top: pxRem(24) !important;
    // gap: pxRem(24);
    justify-content: space-between !important;
    div {
      flex: 1 !important;
    }
    // yes/no components
    .mobile-radio-container {
      input {
        width: pxRem(20) !important;
        height: pxRem(20) !important;
      }
      label {
        font-family: 'OpenSans_Regular';
        font-size: pxRem(16) !important;
        line-height: pxRem(20) !important;
        line-height: pxRem(20) !important;
        letter-spacing: pxRem(0.1px) !important;
      }
    }
  }
  // input component
  .mobile-input-component {
    width: 100% !important;
  }
  // UploadCard components
  .mobile-uploadCard {
    display: block !important;
    margin-top: (6 / $tsc) + rem !important;
    gap: (40 / $tsc) + rem !important;
    width: 100% !important;
  }
  .mobile-document-div {
    flex-direction: column !important;
    margin-top: 0 !important;
    gap: unset !important;
  }
  .mobile-DocumentCard1 {
    width: 100% !important;
  }
  .mobile-title {
    font-family: 'OpenSans_Bold';
    font-size: pxRem(18) !important;
    line-height: pxRem(28) !important;
    color: #333d47;
    margin-bottom: pxRem(12) !important;
  }
  .mobile-detail {
    font-family: 'OpenSans_Regular';
    font-size: pxRem(14) !important;
    line-height: pxRem(20) !important;
    color: #333d47;
    margin-bottom: pxRem(8) !important;
  }
  // general assessment common display block
  .mobile-gap {
    display: flex !important;
    flex-direction: column !important;
    gap: pxRem(24) !important;
  }

  // upload document section
  .mobile-document-card {
    width: 100% !important;
  }
}
