/* open-sans */
@font-face {
  font-family: 'OpenSans_Regular';
  src: url('../fonts/open-sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: 'normal';
}
@font-face {
  font-family: 'OpenSans_Semibold';
  src: url('../fonts/open-sans/OpenSans-Semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: 'normal';
}
@font-face {
  font-family: 'OpenSans_Bold';
  src: url('../fonts/open-sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: 'normal';
}
@font-face {
  font-family: 'OpenSans_Light';
  src: url('../fonts/open-sans/OpenSans-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: 'normal';
}

/* AIA-Everest-Font */
@font-face {
  font-family: 'AIAEverest_Regular';
  src: url('../fonts/AIA-Everest-Font/ttf/AIAEverest-Regular.ttf')
    format('truetype');
  font-weight: normal;
  font-style: 'normal';
}
@font-face {
  font-family: 'AIAEverest_Medium';
  src: url('../fonts/AIA-Everest-Font/ttf/AIAEverest-Medium.ttf')
    format('truetype');
  font-weight: normal;
  font-style: 'normal';
}
@font-face {
  font-family: 'AIAEverest_Bold';
  src: url('../fonts/AIA-Everest-Font/ttf/AIAEverest-Bold.ttf')
    format('truetype');
  font-weight: normal;
  font-style: 'normal';
}
