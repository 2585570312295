@mixin wh-set($w, $h) {
  width: pxRem($w);
  height: pxRem($h);
}
@media screen and (max-width: $screen-size) {
  .mobile-centerDiv {
    margin: pxRem(-9) pxRem(-16) pxRem(-24) pxRem(-16);
    position: unset;
    height: calc(100vh - 5.5rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    inset: unset;
    div {
      text-align: unset;
      margin: unset;
      padding: unset;
    }
    .mobile-BgDiv {
      background-image: url('./images/review/bg.png');
      height: pxRem(310);
      line-height: pxRem(310);
      background-repeat: no-repeat;
      background-size: contain;
      flex: 1;
    }
    img {
      @include wh-set(250, 250);
    }
    .mobile-TextDiv {
      @include fs(24, $A_bold);
      flex: 1;
    }
    .mobile-TextDiv2 {
      @include fs(16, $reg);
      flex: 3;
    }
    .mobile-TextDiv3 {
      padding: pxRem(16);
      button {
        width: 100%;
        height: pxRem(48);
        span {
          @include fs(16, $semi);
        }
      }
    }
  }
  .accordion__item {
    .accordion-header {
      .accordion__button > span {
        @include fs(20, $bold);
        svg {
          @include wh-set(24, 24);
        }
      }
    }
    .accordion-body {
      .mobile-titleDev {
        @include fs(16, $bold);
        display: flex;
        justify-content: space-between;
        p {
          color: #333d47;
        }
        .mobile-btnDiv {
          @include wh-set(24, 24);
          text-align: center;
          img {
            margin-left: 0 !important;
            @include wh-set(16, 16);
          }
        }
      }
    }
  }
  .mobile-review-content {
    .col-div-wrap {
      .subtitle {
        @include fs(14, $reg);
      }
      .subtitle2 {
        @include fs(16, $semi);
      }
      &:not(:last-child) {
        margin: pxRem(16) 0;
      }
    }
  }
  .mobile-label-title {
    margin-top: pxRem(16) !important;
    @include fs(16, $bold);
  }
  .mobile-describe-title {
    margin-top: pxRem(16);
    @include fs(14, $reg);
  }

  .mobile-review-applyRegularPosition {
    padding: pxRem(16) !important;
  }
  .mobile-review-emailLine {
    display: flex;
    align-items: center;
    background-color: #e8f1f6;
    border-radius: pxRem(4) !important;
    height: pxRem(48) !important;
    @include fs(12, $reg);
  }
  .mobile-review-fitAndProper {
    align-items: unset !important;
    margin-top: pxRem(24) !important;
    height: unset !important;
    justify-content: unset !important;
    display: block;
    .mobile-review-fitAndProper-question {
      padding-right: 0 !important;
      @include fs(14, $reg);
      margin-bottom: pxRem(24) !important;
    }
    .mobile-review-fitAndProper-answer {
      right: 0 !important;
      @include fs(16, $semi);
      margin-left: 0 !important;
    }
  }
  .mobile-review-content-countryInstitution {
    margin-top: pxRem(16) !important;
  }
}
