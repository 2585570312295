@media screen and (max-width: $screen-size) {
  .mobile-fit-CriteriaIntroduce {
    font-family: 'OpenSans_Regular';
    font-size: pxRem(16) !important;
    line-height: pxRem(24) !important;
    letter-spacing: pxRem(0.1) !important;
  }
  .mobile-criteria-question-module {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .mobile-criteria-question-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: pxRem(24) !important;
  }
  .mobile-criteria-question {
    display: flex;
    gap: pxRem(16) !important;
    width: 100% !important;
    float: unset;
    .mobile-criteria-question-text {
      font-family: 'OpenSans_Bold';
      font-size: pxRem(16) !important;
      line-height: pxRem(24) !important;
    }
  }
  .mobile-criteria-explain {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    padding-left: pxRem(37) !important;
    font-family: 'OpenSans_Regular';
    font-size: pxRem(16) !important;
    line-height: pxRem(24) !important;
    padding-right: pxRem(8) !important ;
  }
  .mobile-criteria-questionRadio {
    display: flex;
    flex-direction: row;
    float: unset;
    width: 100% !important;
    padding-left: pxRem(37) !important;
    div {
      flex: 1;
    }
    label {
      font-family: 'OpenSans_Regular';
      font-size: pxRem(16) !important;
      line-height: pxRem(20) !important;
      letter-spacing: pxRem(0.1) !important;
    }
  }
  .mobile-criteria-cardDiv {
    padding: pxRem(16) !important;
    .mobile-criteria-documentCard {
      padding: pxRem(16) !important;
      .mobile-criteria-uploadDiv {
        margin-top: pxRem(12) !important;
        width: 100%;
      }
      .mobile-documentCard-title {
        margin-bottom: pxRem(24) !important;
        font-family: 'OpenSans_Semibold';
        font-size: pxRem(16) !important;
        line-height: pxRem(24) !important;
        letter-spacing: pxRem(0.1) !important;
      }
      .mobile-documentCard-content {
        display: flex;
        flex-direction: column;
        .mobile-documentCard1 {
          width: 100%;
          .p1 {
            font-family: 'OpenSans_Bold';
            font-size: pxRem(20) !important;
            line-height: pxRem(24) !important;
          }
          .p2 {
            font-family: 'OpenSans_Bold';
            color: #333d47;
            margin-top: pxRem(5) !important;
            font-size: pxRem(18) !important;
            line-height: pxRem(28) !important;
          }
        }
      }
    }
  }
  .mobile-criterai-textButton24 {
    font-family: 'OpenSans_Semibold';
    // font-size: pxRem(14) !important;
    // line-height: pxRem(20) !important;
    letter-spacing: pxRem(0.1) !important;
    text-align: right;
    padding-left: pxRem(40) !important;
    margin-top: pxRem(22) !important;
  }
}
